// ALL CONTAINERS
.container {
  margin: 2rem 3rem;
  padding: 1rem 1rem 0 1rem;
}

// BOSS-LEVEL CONTAINERS ONLY
#control-container, #login-container, #add-container, #profile-container {
  border-radius: 3rem;
  box-shadow: $container-shadow;
}

// MAIN CONTAINER of CONTROLS - 3 column width
#control-main-container, #login-form-container, #add-led-container {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  gap: 1rem;
  padding: 0 1rem;

  // LEFT COL - artwork
  #control-player-container {
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: $shadow;
    position: relative;
    padding-top: 56.25%;  // this is for apsect ratio friendly containers.  Read here: https://css-tricks.com/aspect-ratio-boxes/
    margin: 0 0.5rem;

    .react-player, > img {
      position: absolute;
      top: 0;
      left: 0;
    }

    > img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  }

  // MIDDLE COL - LED and mood controls
  #control-led-container {
    display: grid;

    #mood-controls {
      margin: 0 auto;
      display: grid;
      align-items: end;
      justify-content: end;
    }
  }

  // RIGHT COL - Profile and volume controls
  #control-volume-container {
    display: grid;
    grid-template-columns: 1fr;
    // justify-content: end;
    // align-items: end;
    padding: 0 1rem;

    #nav-container {
      display: grid;
      // grid-template-columns: 1fr;
      justify-content: end;
    }
  
    #volume-container {
      display: grid;
      grid-template-columns: 1fr;
      // padding: 0 2rem;
      justify-content: end;
      align-items: end;
      .volume input {
        width: 100%;
      }
    }
  }

}

#leftside-container {
  margin-left: 1rem;
}

#login-led-container {
  width: 27rem;
}

#rightside-container {
  display: grid;
  justify-content: end;
  padding-right: 1rem;
}

// PLAY & SEEK CONTAINER
#control-seek-container {
  display: flex;
  align-items: center;

  #progress-bar-container {
    width: 100%;
    margin: 0 2em 0 0;
    position: relative;

    & #current-time-label {
      font-family: $main-font;
      font-size: 1.5rem;
      position: absolute;
      margin-top: 0.5rem;
    }
    & #max-duration-label {
      font-family: $main-font;
      font-size: 1.5rem;
      position: absolute;
      right: 0;
      margin-top: 0.5rem;
    }

  }
}

// GRID UNDERNEATH CONTROLS
#grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5em;
}

.mixtape-card {
  min-height: 8rem;
  // border: 1px solid black;
  display: grid;
  border-radius: 1rem;
  grid-template-columns: 1fr 2fr;
  overflow: hidden;
  box-shadow: $shadow;
  cursor: pointer;
  // box-shadow: $inner-shadow;

  &.checked {
    color: var(--primary);
    box-shadow: $inner-shadow;
  }

  .mixtape-card-artwork {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;

    &#add-artwork {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    // &:hover, &.checked { color: var(--primary); }
  }

  .mixtape-card-info {
    width: 100%;
    // color: var(--greyDark);
    font-family: $main-font;
    font-size: 1.25rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 1rem;

    .artist-name {
      font-weight: bold;
    }

    .artist-name, .track-name {
      margin-bottom: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.mixtape-card:hover #add,
.mixtape-card:hover .mixtape-card-info {
  color: var(--primary);
}

// GRID FOR PROFILE PAGE
#grid-profile-container .mixtape-card {
  max-width: 40rem;
  margin: 1rem auto;
  grid-template-columns: 1fr 2fr 1fr;
}

#grid-profile-container .mixtape-card:first-child {
  margin: 0 auto;
}


// LOGIN & REGISTER LAYOUT
#login-form-container {

  #login-led-container {
    display: grid;

    #login-control-container {
      // width:100%;
      margin: 1em auto;
      text-align: center;
      justify-content: center;

    }
  }
}

// ADD MIXTAPE LAYOUT
#add-form-container {
  
  #enter-url-container {
    margin: 0 auto;
    
    #url-fields {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 0;
      > input {
        // display: flex;
        width: 80%;
        margin-right: 1.5rem;
      }
    }
  }

  #preview-mixtape-container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    #preview-reactplayer {
      padding: 2rem;
    }

    #preview-fields{
      padding: 0 2rem;
    }

    .check-container {
      text-align: center;
      > div label {
        margin-bottom: 0.25rem;
      }
    }
  }

}

// PROFILE PAGE LAYOUT
#change-profile-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 50%;
  margin: auto;
  padding: 0 2rem;

  #change-user-container, #change-mood-container {
    padding: 2rem 0 0 0;
  }

  #mood-controls #mood-radio {
    margin-top: 0.5rem;
  }
}
