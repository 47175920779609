a, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}

// LED Timer
.led {
  font-family: 'Digital-7 Mono', sans-serif;
  font-weight: 700;
  height: 50px;
  // background-color: rgb(127,138,70);
  background-color: rgb(175, 191, 96);
  text-align: center;
  line-height: 50px;
  font-size: 40px;
  border-top: 3px solid #000000ce;
  border-left: 3px solid #000000ce;
  // border-bottom: 2px solid #cfcfcf33;
  // border-right: 2px solid #cfcfcf33;
  border-bottom: 2px solid #cfcfcf33;
  border-right: 2px solid #cfcfcf33;
  border-radius: 5px;
  box-shadow: 0px 0px 50px #000000ee inset;
  text-shadow: 0px 0px 20px #ffff0022;
  // margin: 5px;
  color: #000000aa;
  // color: var(--primary-dark);

  &.track {
    // width: 180px;
    padding: 0 0.5em;
    overflow: hidden;
  }
  &.track > .marquee-text {
    display: inline-block;
    white-space: nowrap;
    // overflow: hidden;
    text-overflow: ellipsis;
    // animation: marquee 10s linear infinite;
  }
  &.track > .marquee-text.scroll {
    animation: marquee 15s linear infinite;
  }
}

/*  PLAY BUTTON  */
.circle {
  grid-column: 2 / 3;
  grid-row: 4 / 6;
  width: 9rem;
  height: 100%;
  justify-self: center;
  border-radius: 1rem;
  display: grid;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  margin: 2em; 

  &__btn {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    // width: 6rem;
    // height: 6rem;
    width: 5rem;
    height: 5rem;
    display: flex;
    margin: .6rem;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 3.2rem;
    color: var(--primary);
    z-index: 300;
    background: var(--greyLight-1);
    box-shadow: $shadow;
    cursor: pointer;
    position: relative;
    &.shadow {box-shadow: $inner-shadow;}

    .play {
      position: absolute;
      opacity: 1;
      transition: all .2s linear;
      &.visibility {
        opacity: 0;
      }
    }
    .pause {
      position: absolute;
      transition: all .2s linear;
      opacity: 0;
      &.visibility {
        opacity: 1;
      }
    }
    // .add {
    //   position: absolute;
    //   opacity: 1;
    //   transition: all .2s linear;
    //   &.visibility {
    //     opacity: 1;
    //   }
    // }
  }

  &__back-1, &__back-2 {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    // width: 6rem;
    // height: 6rem;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    filter: blur(1px);
    z-index: 100;
  }

  &__back-1 {
    box-shadow: .4rem .4rem .8rem var(--greyLight-2), 
    -.4rem -.4rem .8rem var(--white);
    background: linear-gradient(to bottom right, var(--greyLight-2) 0%, var(--white) 100%);
    animation: waves 4s linear infinite;

    &.paused { 
    animation-play-state: paused;
    }
  }

  &__back-2 {
    box-shadow: .4rem .4rem .8rem var(--greyLight-2), 
    -.4rem -.4rem .8rem var(--white);
    animation: waves 4s linear 2s infinite;
    
    &.paused { 
      animation-play-state: paused;
    }
  }
}

/*  RADIO CONTROL */
.radio-control {

  grid-column: 3 / 4;
  grid-row: 1 / 2;
  width: 20rem;
  height: 3rem;
  box-shadow: $shadow;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  position: relative;
  font-family: $main-font;

  input { display: none; }

  > input:checked + label {
    transition: all .5s ease;
    color: var(--primary);
    }

  &__0, &__1, &__2, &__3 {
    width: 6.8rem;
    height: 2.6rem;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--greyDark);
    transition: all .5s ease;

    &:hover { color: var(--primary); }
  }

  &__color {
    position: absolute;
    height: 2.4rem;
    width: 4.4rem;
    margin-left: .3rem;
    border-radius: .8rem;
    box-shadow: $inner-shadow;
    pointer-events: none;
  }
}

#tab-0:checked ~ .radio-control__color {
  transform: translateX(0);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#tab-1:checked ~ .radio-control__color {
  transform: translateX(5rem);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#tab-2:checked ~ .radio-control__color {
  transform: translateX(10rem);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#tab-3:checked ~ .radio-control__color {
  transform: translateX(15rem);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.login-control {

  // grid-column: 2;
  // grid-row: 1;
  width: 13.6rem;
  height: 3rem;
  box-shadow: $shadow;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  // position: relative;
  font-family: $main-font;

  input { display: none; }

  > input:checked + label {
    transition: all .5s ease;
    color: var(--primary);
    }

  &__1, &__2 {
    width: 6.8rem;
    height: 2.6rem;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--greyDark);
    transition: all .5s ease;

    &:hover { color: var(--primary); }
  }

  &__color {
    position: absolute;
    height: 2.4rem;
    width: 6.2rem;
    margin-left: .3rem;
    border-radius: .8rem;
    box-shadow: $inner-shadow;
    pointer-events: none;
  }
}

#tab-1:checked ~ .login-control__color {
  transform: translateX(0);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#tab-2:checked ~ .login-control__color {
  transform: translateX(6.8rem);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

// VOLUME BAR
.volume label {
  font-family: $main-font;
  font-size: 1.25rem;
}

input[type=range]#volume-bar {
  appearance: none;
  height: 1em;
  border-radius: 1rem;
  // box-shadow: $inner-shadow;
  box-shadow: $container-shadow;
}

input[type=range]#volume-bar::-webkit-slider-runnable-track {
  height: 1em;
  cursor: pointer;
  border-radius: 1rem;
  background: linear-gradient(-1deg, var(--primary-dark) 0%, var(--primary) 50%, var(--primary-light) 100%);
  box-shadow: 0px .1rem .3rem 0px var(--greyLight-3);
    
}

input[type=range]#volume-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: var(--white);
  background: radial-gradient(circle, rgba(228,235,245,1) 0%, rgba(255,255,255,1) 100%);
  // border: none;
  box-shadow: 0px .1rem .3rem 0px var(--greyLight-3);
  // box-shadow: 0 0 2px black;
  margin-top: calc(max((1em - 1px - 1px) * 0.5,0px) - 1.5em * 0.5);
  // ::after {
  //   content: '';
  //   position: absolute;
  //   width: 1rem;
  //   height: 1rem;
  //   border-radius: 50%;
  //   box-shadow: $inner-shadow;
  // }
}

// PROGRESS BAR
#progress-bar {
  width: 100%;
}

input[type=range]#progress-bar {
  appearance: none;
  height: 2em;
  border-radius: 1rem;
  // box-shadow: $inner-shadow;
  box-shadow: $container-shadow;
}

input[type=range]#progress-bar::-webkit-slider-runnable-track {
  height: 2em;
  cursor: pointer;
  border-radius: 1rem;
  background: linear-gradient(-1deg, var(--primary-dark) 0%, var(--primary) 50%, var(--primary-light) 100%);
  box-shadow: 0px .1rem .3rem 0px var(--greyLight-3);
}

input[type=range]#progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background: var(--white);
  background: radial-gradient(circle, rgba(228,235,245,1) 0%, rgba(255,255,255,1) 100%);
  // border: none;
  box-shadow: 0px .1rem .3rem 0px var(--greyLight-3);
  // box-shadow: 0 0 2px black;
  margin-top: calc(max((1em - 1px - 1px) * 0.5,0px) - 1.75em * 0.5);
}

// ICONS FOR NAVIGATION
#profile, #home, #add, #delete, #logout {
  width: 50px;
  height: 50px;
  // width: 4rem;
  //   height: 4rem;
  border-radius: 50%;
  box-shadow: $shadow;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: var(--greyDark);
  cursor: pointer;

  .profile  {
    transition: all .5s ease;
  
    &:active {
      box-shadow: $inner-shadow;
      color: var(--primary);
    }
    &:visited {color: inherit;}
  }
  &:hover {color: var(--primary);}
}

#add, #delete {
  width: 5rem;
  height: 5rem;
}

.delete-section {
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
  #delete {
    &:hover {color: #cc001b;}
  }
}




 /*  BUTTONS  */

button {
  border: none;
  // font-family: $main-font;
  font-family: $main-font;
  font-size: 1.5rem;
  width: 15rem;
  height: 3rem;
  border-radius: 1rem;
  box-shadow: $shadow;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: .3s ease;

  &.btn__primary {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    background: var(--primary);
    box-shadow:inset .2rem .2rem 1rem var(--primary-light), 
              inset -.2rem -.2rem 1rem var(--primary-dark),
              $shadow;
    color: var(--greyLight-1);

    &:hover { color: var(--white); }
    &:active {
      box-shadow:inset .2rem .2rem 1rem var(--primary-dark), 
            inset -.2rem -.2rem 1rem var(--primary-light);
    }
}

  &.btn__secondary {
    // grid-column: 1 / 2;
    // grid-row: 5 / 6;
    width: 5rem;
    color: var(--greyDark);
    &:hover { color: var(--primary); }
    &:active {
      box-shadow: $inner-shadow;
    }
  }

p { 
  font-size: 1.6rem;
}
}

/*  CHECKBOX  */
.checkbox { 
  appearance: none;
  width: 2rem;
  height: 2rem;
  box-shadow: $shadow;
  cursor: pointer;
  align-items: center;
  background-color: var(--greyLight-1);
  border-radius: 0.25rem;
  color: var(--primary);
  border: none;
    
  &:hover { 
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(228,235,245,1) 100%);
  }
  
  &:checked {
    background-color: var(--primary);
    background: radial-gradient(circle, var(--primary) 0%, var(--primary) 100%);

  }
}

@keyframes marquee {
  0% { transform: translateX(70%); }
  // 0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}