form:not(#mood-radio) {
  // display: block;
  display: grid;

  div {
    margin-bottom: 1rem;
    align-items: center;

    > label {
      font-family: $main-font;
      display: inline-block;
      width: 150px;
    }
  }

  #split {
    display: flex;
    gap: 1rem;
    margin: 0;
  }

  .login-register-button {
    margin: 1rem auto;
    margin-bottom: 2rem;
  }

}

input[type="text"], input[type="email"], input[type="password"] {
  width: 100%;
  height: 3rem;
  border: none;
  border-radius: 1rem;
  font-size: 1.4rem;
  margin: 0.5rem 0;
  padding-left: 1.4rem;
  box-shadow: $inner-shadow;
  background: none;
  font-family: $main-font;
  color: var(--greyDark);

  &::placeholder { 
    font-family: $main-font;
    color: var(--greyLight-3); 
  }
  &:focus { 
    outline: none; 
    box-shadow: $shadow; 
  }
}