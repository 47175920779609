@font-face {
  font-family: "AtAmiga-Regular";
  src: local("AtAmiga-Regular"),
    url("../../fonts/AtAmiga-Regular.woff")
  }

@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@400;700&display=swap');

:root {
  --primary-light: #8abdff;
  --primary: #6d5dfc;
  --primary-dark: #5b0eeb;
  
  --white: #FFFFFF;
  --greyLight-1: #E4EBF5;
  --greyLight-2: #c8d0e7;
  --greyLight-3: #bec8e4;
  --greyDark: #9baacf;
}

$container-shadow: .8rem .8rem 1.4rem var(--greyLight-2), 
-.2rem -.2rem 1.8rem var(--white);
$shadow: .3rem .3rem .6rem var(--greyLight-2), 
-.2rem -.2rem .5rem var(--white);
$inner-shadow: inset .2rem .2rem .5rem var(--greyLight-2), 
inset -.2rem -.2rem .5rem var(--white);

$brand-font: "AtAmiga-Regular";
// $main-font: "Share Tech Mono", monospace;
$main-font: 'Smooch Sans', sans-serif;

h1 {
  font-family: $brand-font;
  text-shadow:0 -1px 1px rgba(0,0,0,0.5);
  font-size: 2em;
  color: var(--greyLight-3);
  text-align: center;
  margin: 0.25rem;
}

h2 {
  font-size: 1.5em;
  text-align: start;
  margin: 0 3em;
  color: var(--primary-dark)
}